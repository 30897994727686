import Head from 'next/head';
import React, { ReactElement, useEffect } from 'react';
import Router from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import {
  FORM_ERROR,
  Heading,
  Spacing,
  Text
} from 'groundkeeper-component-library';

import Link from 'shared/App/components/Link';
import Layout from 'shared/App/components/Layout';
import Breadcrumb from 'shared/App/components/Breadcrumb';
import LinkList from 'shared/App/components/LinkList';
import LoginForm from 'shared/App/forms/LoginForm';

import Globals from 'shared/App/utils/Globals';
import logoutSideEffects from 'shared/Redux/utils/logout';

import { AppDispatch } from 'shared/Redux/slices';
import { LoginForm as LoginFormInterface } from 'shared/Redux/interfaces';
import { login } from 'shared/Redux/slices/auth.slice';

import selectOrganizations from 'shared/Redux/selectors/organization/selectOrganizations';

export default function Login(props) {
  const intl = useIntl();
  const router = useRouter();
  const dispatch: AppDispatch = useDispatch();
  const organization = useSelector(selectOrganizations)?.[0];

  const { redirectUrl } = router.query;

  async function handleSubmit(values: LoginFormInterface) {
    // setLoading(true);
    logoutSideEffects();

    const result = await dispatch(
      login({ email: values.email, password: values.password })
    );

    // @ts-ignore: TODO: https://redux-toolkit.js.org/usage/usage-with-typescript#defining-action-contents-with-prepare-callbacks
    if (result.error) {
      // setLoading(false);

      return {
        [FORM_ERROR]: intl.formatMessage({
          id: 'error_username_password'
        })
      };
    }

    if (redirectUrl) {
      const queryParams = Object.keys(router.query).filter(
        key => key !== 'redirectUrl'
      );

      let redirectUrlWithParams = redirectUrl;

      if (queryParams.length > 0) {
        const queryParamsString = queryParams
          .map(a => `&${a}=${router.query[a]}`)
          .reduce((a, b) => a + b);

        redirectUrlWithParams = `${redirectUrlWithParams}&${queryParamsString}`;
      }

      router.push(`${redirectUrlWithParams}`);
    } else {
      router.push('/');
    }

    return {};
  }

  useEffect(() => {
    if (
      !organization?.setting_portal_booking_requests &&
      window.location.pathname !== '/'
    ) {
      Router.replace('/');
    }
  }, [organization?.setting_portal_booking_requests]);

  if (!organization?.setting_portal_booking_requests) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{`${Globals.settings.titlePrefix}${intl.formatMessage({
          id: 'login',
          defaultMessage: 'Login'
        })}${Globals.settings.titlePostfix}`}</title>
      </Head>
      <Breadcrumb>
        <Text>
          <Link href="/">
            {intl.formatMessage({
              id: 'sports_portal',
              defaultMessage: 'Sports portal Berlin'
            })}
          </Link>
        </Text>
        <Text>
          {intl.formatMessage({
            id: 'login',
            defaultMessage: 'Login'
          })}
        </Text>
      </Breadcrumb>
      <Spacing y={5} />
      <Heading type="h1">
        {intl.formatMessage({
          id: 'login',
          defaultMessage: 'Login'
        })}
      </Heading>
      <Heading type="h2">Login</Heading>
      <LoginForm handleSubmit={values => handleSubmit(values)} />
      <Spacing y={6} />
      <LinkList
        items={[
          // { title: 'Nutzungsbedingungen', href: '#' }
          // { title: 'Registrieren', href: '#' },
          {
            title: intl.formatMessage({
              id: 'password_reset',
              defaultMessage: 'Password reset'
            }),
            href: '/password_reset'
          }
        ]}
      />
    </>
  );
}

Login.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
