import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  DefaultTheme,
  Heading,
  makeStyles
} from 'groundkeeper-component-library';
import Link from './Link';

const useStyles = makeStyles((theme: DefaultTheme) => ({
  linklist: {
    margin: 0,
    paddingLeft: '1rem',
    '& li': {
      width: 'fit-content',
      marginBottom: theme.spacing(1)
    },
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline !important',
        cursor: 'pointer'
      }
    }
  }
}));

export default function LinkList({
  items
}: PropTypes.InferProps<typeof LinkList.propTypes>) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Heading type="h2">
        {intl.formatMessage({
          id: 'links',
          defaultMessage: 'Links'
        })}
      </Heading>
      <ul
        className={classes.linklist}
        aria-label={intl.formatMessage({
          id: 'links',
          defaultMessage: 'Links'
        })}
      >
        {items.map(item => (
          <li key={item.href}>
            <Box display="flex" gap={1}>
              {typeof item.external === 'boolean' && item.external ? (
                <a
                  style={{ display: 'flex' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                >
                  {item.title}
                </a>
              ) : (
                <Link
                  style={{ display: 'flex' }}
                  title={item.hrefTitle}
                  disabled={item.disabled}
                  href={item.href}
                >
                  {item.title}
                </Link>
              )}
              {item.subtitle}
            </Box>
          </li>
        ))}
      </ul>
    </>
  );
}

LinkList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      title: PropTypes.node.isRequired,
      subtitle: PropTypes.node,
      href: PropTypes.string.isRequired,
      hrefTitle: PropTypes.string,
      external: PropTypes.bool
    })
  )
};

LinkList.defaultProps = {
  items: []
};
